export const environment = {
  production: false,
  auth: {
    clientId: 'a9083915-7cd4-429c-8251-b33134295cae',
    tenantId: 'd1c24379-1a92-47d8-8fd6-a94dfdb2f5b7',
    redirectUri: 'https://customerportal.ingraphicdemo.io/home',
    postLogoutRedirectUri: 'https://customerportal.ingraphicdemo.io/login'
    // redirectUri: 'https://localhost:4200/home',
    // postLogoutRedirectUri: 'https://localhost:4200/login'
  },
  azureFunctionsKey: 'uo7IvbM5xz0EBpN-eMPNPK8IU7ZjOWDNQHUvXLhysgxvAzFu9P6XnA==',
  resources: {
    portalAppAPI: {
      resourceUri: 'https://capp-powex-cp-backend.happydesert-a4380974.norwayeast.azurecontainerapps.io/api',
      resourceScopes: [ 'api://PowationExternalPortal_WEB/access_as_user' ]
    }
  },
  blobStorageIllustrationPhotos: 'https://stpowexcp.blob.core.windows.net/illustration-photos',
  blobStorageConfigSetting: 'https://stpowexcp.blob.core.windows.net/config-files/ConfigSettings.txt'
};
