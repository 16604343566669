import { Component, Input, OnInit } from '@angular/core';
import { PopoverController, IonicModule } from '@ionic/angular';
import { NgFor } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-configuration-popover',
    templateUrl: './configuration-popover.component.html',
    styleUrls: ['./configuration-popover.component.scss'],
    standalone: true,
    imports: [IonicModule, NgFor, TranslateModule],
})
export class ConfigurationPopoverComponent {

  @Input()
  options: Array<PopoverOption> = [];
  @Input()
  textSize: string;

  /**
   *
   */
  constructor(private popoverController: PopoverController) {}

  /**
   *
   */
  closePopover(action: string): void {
    this.popoverController.dismiss({
      action
    }).catch();
  }
}

export interface PopoverOption {
  text: string;
  action: string;
  icon: string;
}

