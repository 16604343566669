import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FrontendConfig } from '../interfaces/frontend-config';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationStore {
  private readonly _config$: BehaviorSubject<FrontendConfig|null> = new BehaviorSubject<FrontendConfig|null>(null);

  get config$(): Observable<FrontendConfig|null> {
    return this._config$.asObservable();
  }

  set config(config: FrontendConfig|null) {
    this._config$.next(config);
  }
}
