import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ModalController, IonicModule } from '@ionic/angular';
import { GroupInterface } from 'src/app/core/interfaces/group.interface';
import { NgIf } from '@angular/common';
import { FrontendConfig } from 'src/app/core/interfaces/frontend-config';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-group-modal',
    templateUrl: './group-modal.component.html',
    styleUrls: ['./group-modal.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        ReactiveFormsModule,
        NgIf,
        TranslateModule
    ],
})
export class GroupModalComponent implements OnInit {
  @Input()
  group?: GroupInterface;
  @Input()
  configuration?: FrontendConfig;
  groupForm!: FormGroup;
  groupFormProcessing = false;
  groupFormSubmitted = false;

  constructor(public modalController: ModalController,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.groupForm = this.formBuilder.group({
      Name: [this.group ? this.group.Name : null, [Validators.required]],
    });
  }

  saveGroup(): void {
    this.groupFormProcessing = true;
    this.groupFormSubmitted = true;
    if (this.groupForm.invalid) {
      this.groupFormProcessing = false;
      return;
    }

    const groupForm = this.groupForm.value;
    void this.modalController.dismiss({
      groupForm,
      action: this.group ? 'edit' : 'create'
    });
  }
  onBack() {
    this.modalController.dismiss(false);
  }
}
