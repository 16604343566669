import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController, IonicModule } from '@ionic/angular';
import { tap, finalize, catchError} from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ResourcesEndpointEnum } from 'src/app/core/enums/resources-endpoint.enum';
import { GroupInterface } from 'src/app/core/interfaces/group.interface';
import { UserInterface } from 'src/app/core/interfaces/user.interface';
import { ResourceService } from 'src/app/core/services/resource.service';
import { UserModalComponent } from '../user-modal/user-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SearchService } from 'src/app/core/services/search.service';
import { NgIf, NgFor } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-add-user-modal',
    templateUrl: './add-user-modal.component.html',
    styleUrls: ['./add-user-modal.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        NgIf,
        NgFor,
        TranslateModule
    ],
})
export class AddUserModalComponent implements OnInit {
  @Input() group?: GroupInterface;
  @Input() users?: UserInterface[] = [];
  @Input() isAddOwner?: boolean = false;
  filteredUsers: UserInterface[] = [];

  constructor(public modalController: ModalController,
              private resourceService: ResourceService,
              private translateService: TranslateService,
              private toastrService: ToastrService,
              private searchService: SearchService,
              private cdr: ChangeDetectorRef,) { }

  ngOnInit() {
    this.filterUsers();
    if(this.isAddOwner) {
      this.group.Users.forEach((user: UserInterface) => {
        if(!user.OwnerOfGroup)
          this.users.push(user)
      })
    }
    this.filteredUsers = this.users;
  }

  giveAccess(user: UserInterface) {
    void this.modalController.dismiss({
      user
    });
  }

  openUserModal(): void {
    this.modalController.create({
      component: UserModalComponent,
      componentProps: {
        addOwner: this.isAddOwner
      },
      cssClass: 'custom-modal',
    }).then((modal: HTMLIonModalElement) => {
      void modal.present();

      modal.onDidDismiss().then((event) => {
        if (event.data?.userForm) {
          this.resourceService.post<UserInterface>(ResourcesEndpointEnum.POST_USER, event.data.userForm, {securityGroupID: this.group.SecurityGroupID})
            .subscribe({
              next: () => {
                this.translateService.get('User invited').subscribe(t => {
                  this.toastrService.success(t);
                })
                this.cdr.detectChanges();
                window.location.reload();
              },
              error: (err) => {
                this.translateService.get('Error occurred when inviting user').subscribe(t => {
                  this.toastrService.error(t);
                })
                this.modalController.dismiss({
                  dismissed: true
                });
                throwError(() => err);
              },
              complete: () => {
                this.modalController.dismiss({
                  dismissed: true
                });
              }
            });
        }
      });
    });
  }

  onSearch(event) {
    const keys = ['FirstName', 'LastName', 'Email'];
    this.filteredUsers = this.searchService?.searchFunction(keys, event, this.users)
  }
  onBack() {
    this.modalController.dismiss(false);
  }
  private filterUsers() {
    this.users = this.users?.filter((user) => {
      let alreadyAccess;

      this.group.Users?.forEach((access) => {
        if (access.UserID === user.UserID) {
          alreadyAccess = true;
        }
      });

      return !alreadyAccess;
    });
  }
}
