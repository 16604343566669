import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, effect } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonDatetime, PopoverController, IonicModule } from '@ionic/angular';
import { ResourcesEndpointEnum } from 'src/app/core/enums/resources-endpoint.enum';
import { WindowSizes } from 'src/app/core/enums/window-sizes.enum';
import { EmbedConfig } from 'src/app/core/interfaces/embed-config';
import { PowerbiService } from 'src/app/core/services/powerbi.service';
import { ResourceService } from 'src/app/core/services/resource.service';
import { PopoverComponent } from '../popover/popover.component';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ReportConfig } from 'src/app/core/interfaces/report-config';
import { Location, NgIf } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UserInterface } from 'src/app/core/interfaces/user.interface';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-embedding-report',
    templateUrl: './embedding-report.component.html',
    styleUrls: ['./embedding-report.component.scss'],
    standalone: true,
    imports: [NgIf, IonicModule, TranslateModule],
})
export class EmbeddingReportComponent implements OnInit, OnChanges, OnDestroy {
  report: ReportConfig;
  embedConfig: any;
  public embedType: string;
  loadingComplete: boolean;
  public windowwidth = innerWidth;
  onmobileView = false;
  windowSizes = WindowSizes;
  group: any;
  public editMode = false;
  public exportingReportProcess = false;
  public exportPercentageComplete = 0.15;
  public bookmarkaction = false;
  public playbookmarkAction = false;
  public embeddingReport = true;
  @Input() reportId: string;
  @Input() reportType: string;
  @Input() dashboardGroupId: number;
  groupID: number;
  startDate: any;
  closingDate: any;
  user: UserInterface;
  control!: AbstractControl | null;
  internalControl!: FormControl;
  projectName: string = '';

  constructor(public popoverController: PopoverController,
              public pbiService: PowerbiService,
              private cdr: ChangeDetectorRef,
              private location: Location,
              private translateService: TranslateService,
              private toastrService: ToastrService,
              public resourceService: ResourceService) {
                effect(() => {
                  this.startDate = null
                  this.resourceService.startDateSubject.next(this.startDate);
                })
               }

  ngOnInit(): void {

    this.loadingComplete = false;
    if (this.windowwidth < this.windowSizes.MaxMobileWidth) {
      this.onmobileView = true;
    }
    this.getReport();
    this.cdr.detectChanges()
  }

  /**
   *
   */
  cancel(dateTime: IonDatetime): void {
    void dateTime.cancel(true);
  }

  /**
   *
   */
  confirm(dateTime: IonDatetime): void {
    dateTime.confirm(true);
  }

  getStartDate(date) {
    this.startDate = date.detail.value;
    this.resourceService.startDateSubject.next(date.detail.value)
  }

  private getReport() {
    this.resourceService.get<ReportConfig>(ResourcesEndpointEnum.GET_REPORT, {groupID: this.dashboardGroupId, reportID: this.reportId})
    .subscribe({
      next: (report: ReportConfig) => {
       this.report = report;
      },
      complete: () => {
      }
    });
  }

  setAsFavorite() {
    this.resourceService.put<ReportConfig>(ResourcesEndpointEnum.PUT_REPORT_FAVORITE, {}, {reportID: this.reportId })
    .subscribe({
      next: () => {
        this.report.UsersFavorite = !this.report.UsersFavorite;
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.translateService.get('Error when toggling favorite report').subscribe(t => {
          this.toastrService.error(t);
        })
        throwError(() => err);
      },
      complete: () => {
      }
    });
  }

  showPopOver() {
    this.presentPopover(1);
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true
    });
    await popover.present();
    const value = (await popover.onDidDismiss()).data;
    this.pbiService.setReportSize(value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.group!== null && this.reportId !== '' && this.reportType !== '') {
      this.loadPowerBIContent(this.reportId, this.reportType, this.dashboardGroupId);
    }
  }
  ngOnDestroy(): void {}

  loadPowerBIContent(reportId: string, reportType: string, groupID: number) {
    // if(groupID === undefined) {
    //   groupID = this.dashboardGroupId
    // }
    this.resourceService.get<EmbedConfig>(ResourcesEndpointEnum.GET_API_REPORT, { groupID: groupID, reportID: reportId, reportType: reportType}).subscribe(
      (data) => {
        this.embedConfig = data;
      },
      (err) => {
        this.loadingComplete = true;
        this.translateService.get('Error loading the report').subscribe(t => {
          this.toastrService.error(t);
        })
      },
      () => {
          this.loadingComplete = true;
          this.embeddingReport = this.embedConfig.EmbedType !== 'Dashboard' ? true: false;
          this.pbiService.retrieveEmbedObject(this.embedConfig);
      }
    );
  }

  goBack() {
    this.location.back()
  }
}
