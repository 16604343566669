import { Component, Input, OnInit } from '@angular/core';
import { PopoverController, IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss'],
    standalone: true,
    imports: [IonicModule, TranslateModule]
})
export class PopoverComponent implements OnInit {

  constructor(private popoverCtrl: PopoverController) { }

  ngOnInit(): void {
  }
  dismissPopover(value: number){
    return this.popoverCtrl.dismiss(value);
  }

}
