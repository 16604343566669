import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { ResourcesEndpointEnum } from 'src/app/core/enums/resources-endpoint.enum';
import { GroupInterface } from 'src/app/core/interfaces/group.interface';
import { HelpPageInterface } from 'src/app/core/interfaces/help-page.interface';
import { UserInterface } from 'src/app/core/interfaces/user.interface';
import { ResourceService } from 'src/app/core/services/resource.service';
import { SearchService } from 'src/app/core/services/search.service';
import { IonicModule } from '@ionic/angular';
import { NgFor, NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-user-help-page',
    templateUrl: './user-help-page.component.html',
    styleUrls: ['./user-help-page.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        NgFor,
        RouterLink,
        NgIf,
        TranslateModule
    ],
})
export class UserHelpPageComponent implements OnInit {
  helpPageList: any;

  filteredHelpPages: HelpPageInterface[] = [];
  columns: Array<any> = [];

  showLoadingHelpPages = false;
  @ViewChild('helpPageTemplate', { static: true }) helpPageTemplate!: TemplateRef<any>;

  helpPages: any;

  constructor(private resourceService: ResourceService,
              private toastrService: ToastrService,
              private searchService: SearchService,
              private router: Router,
              private translateService: TranslateService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.getUserHelpPages();
    this.cdr.detectChanges()

  }

  onSearch(event: any) {
    if(!event.target.value.toString().toLowerCase()) {
      this.getUserHelpPages();
    }
    const keys = ['Title', 'Description'];
    const arrays = ['Sections[Title]']
    this.helpPageList = this.searchService.searchFunction(keys, event, this.helpPageList, arrays);
  }

  initDataTable() {
    this.columns = [{
      cellTemplate: this.helpPageTemplate
    }];
  }

  redirectSection(section: any) {
    this.router.navigate(['home/help-pages-detail/' + section.HelpPageID], {queryParams: {section: section.Title}});
  }

  getUserHelpPages() {
    this.resourceService.getCollection<HelpPageInterface[]>(ResourcesEndpointEnum.GET_HELP_PAGES)
    .subscribe({
      next: (helpPage: HelpPageInterface[][]) => {
        this.helpPageList = helpPage;
        this.helpPageList.forEach((helpPage: any) => {
          helpPage.Sections = helpPage.Sections.filter((section: any) => section.Collapsable);
        });
        this.sortHelpPages();
        this.cdr.detectChanges()
      },
      error: (err) => {
        this.translateService.get('Error loading help pages').subscribe(t => {
          this.toastrService.error(t);
        })
        this.showLoadingHelpPages = false;
        throwError(() => err)
      },
      complete: () => {
        this.showLoadingHelpPages = false;
        }
      }
    );
  }
  private sortHelpPages() {
    this.helpPageList = this.helpPageList.sort((a: HelpPageInterface, b: HelpPageInterface) => {
      if (!a.Pinned && b.Pinned) {
          return 1;
      }
      if (a.Pinned && !b.Pinned) {
          return -1;
      }
  });
  }
}
